<template>
    <v-popover
        :trigger="pTrigger"
        :open="isOpened"
        :placement="pPlacement"
        offset="pOffset"
        :delay="{
          hide: pHide,
        }"
        :auto-hide="pAutoHide"
        :popoverInnerClass="pPopoverInnerClass"
    >
        <div
            class="popover__trigger"
            @mouseenter="open"
            @mouseleave="close"
        >
            <slot name="header"/>
        </div>

        <template slot="popover" class="submenu__content">
            <div
                class="popover__body"
                @mouseenter="hoverOnBody"
                @mouseleave="removeHoverOnBody"
            >
                <slot name="body"/>
            </div>
        </template>
    </v-popover>
</template>

<script>
export default {
    name: "VuePopover",
    props: {
        pTrigger: {
            type: String,
            required: false,
            default: 'manual'
        },
        pPlacement: {
            type: String,
            required: false,
            default: 'bottom-end'
        },
        pOffset: {
            type: [String, Number],
            required: false,
            default: '1'
        },
        pHide: {
            type: [Number],
            required: false,
            default: 300
        },
        pAutoHide: {
            type: Boolean,
            required: false,
            default: false
        },
        pPopoverInnerClass: {
            type: String,
            required: false,
            default: 'popover__inner'
        }
    },
    data () {
        return {
            isOpened: false,
            timer: null,
            isBodyHovered: false
        }
    },
    methods: {
        hoverOnBody() {
            this.isBodyHovered = true;
        },
        removeHoverOnBody() {
            this.isBodyHovered = false;
            this.close();
        },
        open () {
            this.isOpened = true;
        },
        close() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                if (!this.isBodyHovered) {
                    this.isOpened = false;
                }
            }, 500);
        }
    },
}
</script>

<style scoped>

</style>
